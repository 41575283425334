/* eslint-disable camelcase */
import { fetchWithToken, fetch } from 'utils/fetch';
import { checkSuccess, urlEncodeBody } from 'utils/requests';
import { mapDocumentsTree, mapListingDocuments } from 'utils/mapping';
import { DropdownOption } from '@xchange/uikit';
import { Doctypes } from './api.d';

export async function getFoldersWithDocuments(): Promise<AnyNode[]> {
  const response = await fetchWithToken('/documents');
  const data = await response.json();

  return mapDocumentsTree(data);
}

export async function createFolder(values: { name: string; parentId?: number }): Promise<void> {
  await fetchWithToken('/documents/folder', {
    method: 'POST',
    body: urlEncodeBody({ folder: values.name, parent_id: values.parentId })
  });
}

export async function sendSignatureReminder(
  member_id: number,
  document_id: number
): Promise<void> {
  const response = await fetchWithToken(`/documents/pdf_editor/`+document_id+`/signature_reminder`, {
    method: 'POST',
    body: urlEncodeBody({
      member_id: member_id,
    })
  });
}

export async function uploadDocument(values: {
  file: File;
  folder?: string;
  listing?: string;
  doctype?: Doctypes;
  new_doctype?: string;
  doctypeName?: string;
  draft?: boolean;
  base_price?: number;
  earnest_money_1?: number;
  earnest_money_2?: number;
  closing_cost_credits?: number;
  effective_date?: number;
  attorney_review_deadline?: number;
  inspection_due_deadline?: number;
  mortgage_contingency_cleartoclose_deadline?: number;
  extras_total?: number;
  commissions?: number;
  additional_commission?: number;
  reimbursement_fee?: number;
  mls_fee?: number;
  marketing_fee?: number;
  sale_coop_commission?: number;
  em_role_specific?: string;
  earnest_money_1_deposit?: number;
  earnest_money_2_deposit?: number;
  earnest_money_3_deposit?: number;
  earnest_money_4_deposit?: number;
  publish_date?: number;
  expiration_date?: number;
  current_price?: number;
  property_type?: string;
  offer_price?: number;
  financials?: string;
  downpayment?: number;
  earnest_money?: number;
  closing_date?: number;
  expenses?: number;
  userId?: number;
  publicAccess?: boolean;
  name?: string;
  email?: string;
  phone?: string;
}): Promise<number> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetchWithToken('/documents', {
      method: 'POST',
      body: urlEncodeBody({
        filename: values.file.name,
        size: values.file.size,
        doctype_id: values.doctype,
        doctype: values.doctypeName,
        folder_id: values.folder,
        listing_id: values.listing,
        is_draft: values.draft ? 1 : 0,
        base_price: values.base_price,
        earnest_money_1: values.earnest_money_1,
        earnest_money_2: values.earnest_money_2,
        closing_cost_credits: values.closing_cost_credits,
        effective_date: values.effective_date,
        attorney_review_deadline: values.attorney_review_deadline,
        inspection_due_deadline: values.inspection_due_deadline,
        mortgage_contingency_cleartoclose_deadline:
          values.mortgage_contingency_cleartoclose_deadline,
        extras_total: values.extras_total,
        commissions: values.commissions,
        additional_commission: values.additional_commission,
        reimbursement_fee: values.reimbursement_fee,
        mls_fee: values.mls_fee,
        marketing_fee: values.marketing_fee,
        sale_coop_commission: values.sale_coop_commission,
        em_role_specific: values.em_role_specific,
        earnest_money_1_deposit: values.earnest_money_1_deposit,
        earnest_money_2_deposit: values.earnest_money_2_deposit,
        earnest_money_3_deposit: values.earnest_money_3_deposit,
        earnest_money_4_deposit: values.earnest_money_4_deposit,
        publish_date: values.publish_date,
        expiration_date: values.expiration_date,
        current_price: values.current_price,
        property_type: values.property_type,
        offer_price: values.offer_price,
        financials: values.financials,
        downpayment: values.downpayment,
        earnest_money: values.earnest_money,
        closing_date: values.closing_date,
        expenses: values.expenses,
        user_id: values.userId,
        public_access: values.publicAccess ? 1 : 0,
        name: values.name,
        email: values.email,
        phone: values.phone
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: values.file });
  // confirm upload success
  await fetchWithToken(`/documents/${documentId}`, { method: 'PUT' });

  return documentId;
}

export async function deleteDocument(id: number | string): Promise<void> {
  await fetchWithToken(`/documents/${id}`, { method: 'DELETE' });
}

export async function deleteFolder(id: string): Promise<void> {
  await fetchWithToken(`/documents/folder/${id}`, { method: 'DELETE' });
}

export async function downloadDocument(
  id: string | number
): Promise<{ name: string; url: string }> {
  const query = new URLSearchParams();
  query.append(`ids[0]`, String(id));

  const response = await fetchWithToken('/documents/download', {
    method: 'GET',
    query: query.toString()
  });
  const data = await response.json();

  return { name: data.documents[0].name, url: data.documents[0].url };
}

export async function executeOfferRequest(document: WorkspaceDocument, pageStart: number, fillSellSideData: boolean, AttorneyId: number): Promise<boolean> {
  const response = await fetchWithToken(`/offers/respond`, {
    method: 'POST',
    body: urlEncodeBody({
      document_id: document?.id,
      start: (pageStart),
      fill_sell_side_data: fillSellSideData?1:0,
      attorney_vendor_id: AttorneyId
    })
  });

  const data = await response.json();

  return data.success;
}

export async function shareDocuments(value: {
  ids: string;
  teamMembers?: number[];
  listingMembers?: number[];
  customEmails?: string[];
  zipFormat?: number;
  message?: string;
}): Promise<void> {
  await fetchWithToken('/documents/share', { method: 'POST', body: urlEncodeBody(value) });
}

export async function getDocuments(listingId: string): Promise<WorkspaceDocument[]> {
  const response = await fetchWithToken(`/documents/listing/${listingId}`);

  const data = await response.json();

  return mapListingDocuments(data);
}

export async function getExternalDocuments(listingId: string): Promise<WorkspaceDocument[]> {
  const response = await fetchWithToken(`/documents/listing/xp/${listingId}`);

  const data = await response.json();

  return mapListingDocuments(data);
}

export async function getAllDocuments(): Promise<DropdownOption[]> {
  const response = await fetchWithToken(`/documents`);

  const data = await response.json();
  checkSuccess(data);

  return data.map(item => ({
    value: item.id,
    text: item.filename
  }));
}

export async function deleteMultipleDocuments(ids: number[]): Promise<void> {
  const body = new URLSearchParams();
  ids.forEach((id, idx) => {
    body.append(`ids[${idx}]`, String(id));
  });
  await fetchWithToken('/documents/multiple', { method: 'DELETE', body });
}

export async function downloadDocumentsArchive(ids: number[]): Promise<string> {
  const query = new URLSearchParams();
  ids.forEach((id, idx) => {
    query.append(`ids[${idx}]`, String(id));
  });
  const response = await fetchWithToken('/documents/zip', {
    method: 'GET',
    query: query.toString()
  });

  const downloadUrl = URL.createObjectURL(await response.blob());

  return downloadUrl;
}

// document types
export async function getDocumentTypes(): Promise<DocumentType[]> {
  const response = await fetchWithToken(`/documents/types`);
  const data = await response.json();

  checkSuccess(data);

  return data.map(doc => ({
    id: doc.doctype_id,
    name: doc.docname,
    key: doc.doctype,
    category: doc.doccat
  }));
}

export async function createDocumentFromTemplate(
  value: {
    listing_id: string;
    expense_sum?: number;
    comment?: string;
    seller_broker_commission_percent?: number;
    seller_broker_commission_flat_rate_extract?: number;
    seller_broker_commission_flat_rate_add?: number;
    buyer_broker_commission_percent?: number;
    buyer_broker_commission_flat_rate_extract?: number;
    buyer_broker_commission_flat_rate_add?: number;
    mls_fee?: number;
    county_taxes?: number;
    processing_fees?: number;
    endorsements?: string;
  },
  templateName: string
): Promise<{ formId: number }> {
  const response = await fetchWithToken(`/templates/${templateName}`, {
    method: 'POST',
    body: urlEncodeBody(value)
  });
  const data = await response.json();

  return {
    formId: data.form_id
  };
}

export async function uploadFileToS3(
  file: File,
  config?: { publicAccess?: boolean; listingId?: any; doctype: string }
): Promise<{
  url: string;
  documentId: number;
}> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetchWithToken('/documents', {
      method: 'POST',
      body: urlEncodeBody({
        filename: file.name,
        size: file.size,
        public_access: config?.publicAccess ? 1 : 0,
        listing_id: config?.listingId,
        doctype: config?.doctype
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: file });
  // confirm upload success
  const { url } = await (
    await fetchWithToken(`/documents/${documentId}`, { method: 'PUT' })
  ).json();

  return { url, documentId };
}

export async function getDocumentVariablesValues(
  listingId: string
): Promise<{ [key: string]: string | number }> {
  const response = await fetchWithToken(`documents/data/${listingId}`);
  const data = await response.json();

  checkSuccess(data);

  const {
    attorney_review_deadline,
    closing_date,
    expiration_date,
    publish_date,
    effective_date,
    inspection_due_deadline,
    mortgage_contingency_cleartoclose_deadline,
    ...item
  } = data;

  return {
    attorney_review_deadline: attorney_review_deadline * 1000,
    closing_date: closing_date * 1000,
    expiration_date: expiration_date * 1000,
    publish_date: publish_date * 1000,
    effective_date: effective_date * 1000,
    inspection_due_deadline: inspection_due_deadline * 1000,
    mortgage_contingency_cleartoclose_deadline: mortgage_contingency_cleartoclose_deadline * 1000,
    ...item
  };
}

export async function sendRequest(values: {
  email: string;
  broker_filling: 1 | 0;
  listing_id: string;
  contract_type: string;
}): Promise<string> {
  const response = await fetchWithToken('/documents/generate/send_request', {
    method: 'POST',
    body: urlEncodeBody(values)
  });
  const data = await response.json();

  checkSuccess(data);

  return data.redirect;
}

export async function getDocumentForm(viewId: string): Promise<DocumentFormField[]> {
  const response = await fetch(`/documents/generate/external/data/${viewId}`);
  const data = await response.json();

  checkSuccess(data);

  return data.map((fields) => (
    fields.map((field) => (
      {
        name: field.field_name,
        choices: field.field_choices.split(';'),
        values: field.field_values.split(';'),
        question: field.question,
        type: field.type,
        contract_type: field.contract_type,
        document_name: field.document_name
      }
    ))
  ));
}

export async function getExternalDocumentForm(documentType: string): Promise<any> {
  const response = await fetchWithToken(`/documents/generate/data`, {
    method: 'POST',
    body: urlEncodeBody({
      contract_type: documentType
    })
  });
  const data = await response.json();

  checkSuccess(data);
  return data.map((fields) => (
    fields.map((field) => (
      {
        name: field.field_name,
        choices: field.field_choices.split(';'),
        values: field.field_values.split(';'),
        question: field.question,
        type: field.type,
        contract_type: field.contract_type,
        document_name: field.document_name,
        defaultValue: field.default_field_value
      }
    ))
  ));

}

export async function generateExternalDocumentData(values: Object, viewId: string): Promise<void> {
  await fetch(`/documents/generate/external/create/${viewId}`, {
    method: 'POST',
    body: urlEncodeBody({
      data: JSON.stringify(values)
    })
  });
}

export async function generateDocumentData(
  values: Object,
  listingId: string,
  documentType: string
): Promise<number> {
  const response = await fetchWithToken(`/documents/generate/create`, {
    method: 'POST',
    body: urlEncodeBody({
      listing_id: listingId,
      contract_type: documentType,
      data: JSON.stringify(values)
    })
  });

  const data = await response.json();

  checkSuccess(data);

  return data.documentIds?.[0];
}

const mapDocumentField = ({ field_name, field_choices, field_values, ...item }) => ({
  name: field_name,
  choices: field_choices.split(';'),
  values: field_values.split(';'),
  ...item
});
